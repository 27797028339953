// vendors
import classNames from 'classnames';
import { m as motion, useAnimation } from 'framer-motion';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import mainNavigationItems from '../../data/mainNavigationItem.json';
import WaveButton from '../Button/WaveButton';
import AubergeLogotype from '../Logo/AubergeLogotype';
import LangugageSwitcher from './LanguageSwitcher';
import * as styles from './SiteHeader.module.css';


import CookieConsentCSS from '../CookieConsent/CookieConsentCSS';
import CookieConsent, {
  doNecessaryCookieAuthorizedForVar,
  doAnalyticCookieAuthorizedForVar,
  doAdsCookieAuthorizedForVar,
  getCookie
} from '../CookieConsent/CookieConsent';

function SiteHeader({ alternativesLanguages }) {
  const intl = useIntl();
  const controls = useAnimation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [consentState, setConsentState] = useState(null); // État pour consentement

  const menuItems = React.useMemo(
    () => mainNavigationItems[intl.locale],
    [intl.locale]
  );

  const handleButtonClick = () => {
    setIsOpen((prev) => !prev);

    controls.start({ x: isOpen ? 0 : '-100%' });
  };

  const handleLinkClick = () => {
    setIsOpen(false);

    controls.start({ x: 0 });
  };


  const loadConsentState = () => {
    try {
      const cookieData = getCookie('cc_cookie');
      if (cookieData) {
        const parsedCookie = JSON.parse(cookieData);
        // Mettre à jour consentState uniquement si nécessaire
        if (JSON.stringify(consentState) !== JSON.stringify(parsedCookie)) {
          setConsentState(parsedCookie);  // Mise à jour de l'état
        }
      }
    } catch (error) {
      console.error('Failed to parse cc_cookie:', error);
    }
  };
  
  // Charger les informations de consentement au démarrage
  useEffect(() => {
    loadConsentState();
  }, []); // Se lance une seule fois au début
  
  // Utiliser useEffect pour observer les changements de consentState
  useEffect(() => {
    // Ce useEffect se déclenche chaque fois que consentState est mis à jour
    if (consentState) {
        
      if (doNecessaryCookieAuthorizedForVar(consentState)) {
        console.log('✅ Necessary cookies enabled, loading essential scripts...');
        // do nothing ! Necessary cookies are ... necessary
      }
  
      if (doAnalyticCookieAuthorizedForVar(consentState)) {
        console.log('📊 Analytics cookies enabled, loading Google Analytics...');
        // Ajouter ici le script pour mettre Google Analytics si nécessaire
        // Exemple:
        /*
          const script = document.createElement('script');
          script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-XXXXXX-X';
          script.async = true;
          document.body.appendChild(script);
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16905938774">
            </script>
            <script>
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16905938774');
            </script>
        */
          ////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Créer le premier script (Google Tag Manager)
          const gtmScript = document.createElement('script');
          gtmScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16905938774';
          gtmScript.async = true;

          // Insérer avant la fermeture de </head>
          document.head.appendChild(gtmScript);

          // Créer le second script (gtag configuration)
          const gtagScript = document.createElement('script');
          gtagScript.innerHTML = `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16905938774');
          `;

          // Insérer avant la fermeture de </head>
          document.head.appendChild(gtagScript);
          
          ////////////////////////////////////////////////////////////////////////////////////////////////////////
          // Créer le script pour Google Tag Manager
          const gtmScript2 = document.createElement('script');
          gtmScript2.innerHTML = `
              (function(w,d,s,l,i){
                  w[l]=w[l]||[];
                  w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
                  var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
                  j.async=true;
                  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                  f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-PXWJ9C7P');
          `;

          // Insérer avant la fermeture de </head>
          document.head.appendChild(gtmScript2);

          ////////////////////////////////////////////////////////////////////////////////////////////////////////
          // Créer l'élément <noscript>
          const gtmNoscript = document.createElement('noscript');

          // Ajouter le contenu de l'iframe GTM
          const gtmIframe = document.createElement('iframe');
          gtmIframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-PXWJ9C7P";
          gtmIframe.height = "0";
          gtmIframe.width = "0";
          gtmIframe.style.display = "none";
          gtmIframe.style.visibility = "hidden";

          // Ajouter l'iframe à <noscript>
          gtmNoscript.appendChild(gtmIframe);

          // Insérer juste après <body>
          if (document.body) {
            document.body.insertAdjacentElement('afterbegin', gtmNoscript);
          }
          
      }
  
      if (doAdsCookieAuthorizedForVar(consentState)) {
        console.log('📢 Ads cookies enabled, loading advertisement scripts...');
        // Ajouter ici le script pour ne pas mettre les script d'ads
      }
    }
  }, [consentState]);  // Ce useEffect se déclenche chaque fois que consentState change



  return (
    <header className="overflow-hidden fixed top-0 z-10 w-screen pointer-events-none px-container">
      
      <div className="flex relative z-20 justify-between py-4">
        <div className="pointer-events-auto">
          <LangugageSwitcher alternativesLanguages={alternativesLanguages} />
        </div>

        <div className="h-11 pointer-events-auto">
          <WaveButton onClick={handleButtonClick} isOpen={isOpen} />
        </div>
      </div>

      <motion.div
        animate={controls}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        className="flex fixed inset-y-0 left-full z-10 flex-col w-screen h-screen bg-paleOrange-600 pointer-events-auto md:py-20 md:pl-12 md:w-auto px-container md:pr-container"
      >
        <div className="text-center md:hidden">
          <div className="inline-block px-12 mt-20 mb-16 max-w-sm">
            <AubergeLogotype />
          </div>
        </div>

        <nav className="grow md:mr-32">
          <ul className="space-y-6 text-center md:text-left ">
            {menuItems.map((item) => (
              <li>
                <Link
                  to={item.href}
                  className={classNames(
                    'font-title whitespace-nowrap fluid-text-[1.25/2] hover:text-paleOrange-300 transition-colors',
                    styles.menuLink
                  )}
                  onClick={handleLinkClick}
                >
                  {item.label}
                </Link>

                {item.subNav && item.subNav.length > 0 && (
                  <ul className="">
                    {item.subNav.map((subItem) => (
                      <li>
                        <Link
                          to={subItem.href}
                          className={classNames([
                            'whitespace-nowrap fluid-text-[1/1.25] hover:text-paleOrange-300 transition-colors',
                            styles.subMenuLink,
                          ])}
                          onClick={handleLinkClick}
                        >
                          {subItem.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </motion.div>

      <div className="flex fixed right-0 bottom-0 z-20 flex-col items-stretch pt-2 pb-4 mx-auto space-y-2 w-screen md:items-end px-container">
        <a
          href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking"
          className={classNames([
            'flex justify-center items-center py-4 w-full text-center uppercase align-middle rounded-full pointer-events-auto md:aspect-square md:w-32 md:h-32 bg-midnightGreen-700 text-paleOrange-700 text-sm hover:text-midnightGreen-700 hover:bg-paleOrange-300 transition-colors',
            styles.ctaButton,
          ])}
        >
          <span>
            <FormattedMessage
              defaultMessage="Reserver une{nbsp}chambre"
              values={{ nbsp: <>&nbsp;</> }}
            />
          </span>
        </a>

        <a
          href="https://booking.libroreserve.com/23ef236e8f8376e/QC016179900591?affiliate-code=WEB&restaurant-auto-select=true"
          className={classNames([
            'flex justify-center items-center py-4 w-full text-center uppercase align-middle rounded-full pointer-events-auto md:aspect-square md:w-32 md:h-32 text-midnightGreen-700 bg-paleOrange-300 text-sm hover:bg-midnightGreen-700 hover:text-paleOrange-700 transition-colors',
            styles.ctaButton,
          ])}
        >
          <span>
            <FormattedMessage
              defaultMessage="Reserver une{nbsp}table"
              values={{ nbsp: <>&nbsp;</> }}
            />
          </span>
        </a>
      </div>
    </header>
  );
}

export default SiteHeader;

SiteHeader.propTypes = {
  alternativesLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      href: PropTypes.string,
    })
  ),
};

SiteHeader.defaultProps = {
  alternativesLanguages: [
    { locale: 'en', href: '/en' },
    { locale: 'fr', href: '/' },
  ],
};
