import React, { useEffect } from 'react';

// Importation conditionnelle côté client
let cs = null;
if (typeof window !== 'undefined') {
  cs = require('../../libraries/cookieconsent');
}




// État global pour stocker les catégories autorisées
const consentState = {
  categories: [],
};

// Fonctions globales pour chaque catégorie
export const doNecessaryCookieAuthorized = () => {
  return consentState.categories.includes('necessary');
};

export const doAnalyticCookieAuthorized = () => {
  return consentState.categories.includes('analytics');
};

export const doAdsCookieAuthorized = () => {
  return consentState.categories.includes('ads');
};



// Fonctions globales pour chaque catégorie
export const doNecessaryCookieAuthorizedForVar = (varr) => {
  return varr.categories.includes('necessary');
};

export const doAnalyticCookieAuthorizedForVar = (varr) => {
  return varr.categories.includes('analytics');
};

export const doAdsCookieAuthorizedForVar = (varr) => {
  return varr.categories.includes('ads');
};


// Fonction utilitaire pour lire un cookie
export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? decodeURIComponent(match[2]) : null;
};


const CookieConsent = ({ langcode = 'en' }) => {
  useEffect(() => {
    // Affiche une popup au montage du composant si un titre ou un message est défini
    
    if (cs) {

      cs.run({

        // root: 'body',
        // autoShow: true,
        // disablePageInteraction: true,
        // hideFromBots: true,
        // mode: 'opt-in',
        // revision: 0,
    

        cookie: {
            name: 'cc_cookie',
            // domain: location.hostname,
            // path: '/',
            // sameSite: "Lax",
             expiresAfterDays: 3,
        },
    
        guiOptions: {
            consentModal: {
                layout: 'cloud inline',
                position: 'bottom center',
                equalWeightButtons: true,
                flipButtons: false
            },
            preferencesModal: {
                layout: 'box',
                equalWeightButtons: true,
                flipButtons: false
            }
        },
    
        onFirstConsent: ({cookie}) => {
            console.log('onFirstConsent fired',cookie);
            window.location.reload();
        },
    
        onConsent: ({cookie}) => {
            console.log('onConsent fired!', cookie);

            const cookieData = getCookie('cc_cookie');

            if (cookieData) {
              try {
                const parsedCookie = JSON.parse(cookieData);
                consentState.categories = parsedCookie.categories || [];
              } catch (error) {
                console.error('Failed to parse cc_cookie:', error);
              }
            }
            
        },
    
        onChange: ({changedCategories, changedServices}) => {
            console.log('onChange fired!', changedCategories, changedServices);
        },
    
        onModalReady: ({modalName}) => {
            console.log('ready:', modalName);
        },
    
        onModalShow: ({modalName}) => {
            console.log('visible:', modalName);
        },
    
        onModalHide: ({modalName}) => {
            console.log('hidden:', modalName);
        },
    
        categories: {
            necessary: {
                enabled: true,  // this category is enabled by default
                readOnly: true  // this category cannot be disabled
                
            },
            analytics: {
                autoClear: {
                    cookies: [
                        {
                            name: /^_ga/,   // regex: match all cookies starting with '_ga'
                        },
                        {
                            name: '_gid',   // string: exact cookie name
                        },
                        {   
                            name: /^_gcl/,  // regex: Match for all google tag manager
                        }
                    ]
                },
    
                // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
                services: {
                    ga: {
                        label: 'Google Analytics',
                        onAccept: () => {},
                        onReject: () => {}
                    },
                    gid: {
                        label: 'Google Analytics',
                        onAccept: () => {},
                        onReject: () => {}
                    }
                  /*  ,
                    youtube: {
                        label: 'Youtube Embed',
                        onAccept: () => {},
                        onReject: () => {}
                    },
                    */
                }
            },
            ads: {
                autoClear: {
                    cookies: [
                        {
                            name: /^_ga/,   // regex: match all cookies starting with '_ga'
                        },
                        {
                            name: '_gid',   // string: exact cookie name
                        },
                        {   
                            name: /^_gcl/,  // regex: Match for all google tag manager
                        }
                    ]
                },
    
                // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
                services: {
                    ga: {
                        label: 'Google Tags Manager',
                        onAccept: () => {},
                        onReject: () => {}
                    }
                }
            }
        },
    
        language: {
            default: langcode,
            translations: {
                en: {
                    consentModal: {
                        title: 'We may use cookies',
                        description: 'With your consent, we would like to use cookies or similar technological tools on our sites. This data helps us adapt our content to make your visit more enjoyable and efficient. By clicking "Accept", you agree to this use.',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        showPreferencesBtn: 'Manage Individual preferences',
                        // closeIconLabel: 'Reject all and close modal',
                        footer: `
                            <a href="/en/privacy" >Privacy Policy</a><br>
                            <a href="/en/cookie-policy" >Cookie management policy</a>
                        `,
                    },
                    preferencesModal: {
                        title: 'Manage cookie preferences',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        savePreferencesBtn: 'Accept current selection',
                        closeIconLabel: 'Close modal',
                        serviceCounterLabel: 'Service|Services',
                        sections: [
                            {
                                title: 'Your Privacy Choices',
                                description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
                            },
                            {
                                title: 'Strictly Necessary',
                                description: 'These cookies are essential for the proper functioning of the website and cannot be disabled. This include the cc_cookie for the cookie consent manager.',
    
                                //this field will generate a toggle linked to the 'necessary' category
                                linkedCategory: 'necessary',
                                cookieTable: {
                                    caption: 'Cookie table',
                                    headers: {
                                        name: 'Cookie',
                                        domain: 'Domain',
                                        lifeSpan: 'Life span',
                                        desc: 'Description'
                                    },
                                    body: [
                                        {
                                            name: 'cc_cookie',
                                            domain: location.hostname,
                                            lifeSpan: "3 days",
                                            desc: "This cookie allows you to save your choice in terms of cookie usage preferences.",
                                        }
                                    ]
                                }
                            },
                            {
                                title: 'Performance and Analytics',
                                description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                linkedCategory: 'analytics',
                                cookieTable: {
                                    caption: 'Cookie table',
                                    headers: {
                                        name: 'Cookie',
                                        domain: 'Domain',
                                        lifeSpan: 'Life span',
                                        desc: 'Description'
                                    },
                                    body: [
                                        {
                                            name: '_ga',
                                            domain: location.hostname,
                                            lifeSpan: "90 days",
                                            desc: 'Browser cookie linked to Google Analytics.',
                                        },
                                        {
                                            name: '_gid',
                                            domain: location.hostname,
                                            lifeSpan: "3 days",
                                            desc: 'Browser cookie linked to Google Analytics.',
                                        }
                                    ]
                                }
                            },
                            {
                                title: 'Targeting and Advertising',
                                description: 'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                                linkedCategory: 'ads',
                                cookieTable: {
                                    caption: 'Cookie table',
                                    headers: {
                                        name: 'Cookie',
                                        domain: 'Domain',
                                        lifeSpan: 'Life span',
                                        desc: 'Description'
                                    },
                                    body: [
                                        {
                                            name: '_gcl_au',
                                            domain: location.hostname,
                                            lifeSpan: "90 days",
                                            desc: 'This cookie is required to manage a Google Ads campaign to promote Auberge sur Mer.',
                                        }
                                    ]
                                }
                            },
                            {
                                title: 'More information',
                                description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-us">contact us</a>'
                            }
                        ]
                    }
                },
              fr: {
                    consentModal: {
                        title: 'Nous utilisons des témoins de navigation',
                        description: 'Avec votre accord, nous souhaitons utiliser des cookies ou des outils technologiques similaires sur nos sites. Ces données nous aident à adapter nos contenus pour rendre votre visite plus agréable et efficace. En appuyant sur « Accepter », vous approuvez cette utilisation.',
                        acceptAllBtn: 'Tous accepter',
                        acceptNecessaryBtn: 'Tous rejeter',
                        showPreferencesBtn: 'Gérer mes préférences',
                        // closeIconLabel: 'Reject all and close modal',
                        footer: `
                            <a href="/politique-confidentialite" >Politique de confidentialité</a><br>
                            <a href="/politique-gestion-temoins-navigation" >Politique de gestion des témoins de navigation</a>
                        `,
                    },
                    preferencesModal: {
                        title: 'Gérer mes préférences pour les témoins de navigation',
                        acceptAllBtn: 'Tous accepter',
                        acceptNecessaryBtn: 'Tous rejeter',
                        savePreferencesBtn: 'Accepter la sélection actuelle',
                        closeIconLabel: 'Fermer',
                        serviceCounterLabel: 'Service|Services',
                        sections: [
                            {
                                title: 'Vos choix en matière de confidentialité',
                                description: `Dans ce panneau, vous pouvez exprimer certaines préférences relatives au traitement de vos informations personnelles. Vous pouvez revoir et modifier les choix exprimés à tout moment en revenant sur ce panneau via le lien fourni. Pour refuser votre consentement aux activités de traitement spécifiques décrites ci-dessous, désactivez les boutons ou utilisez le bouton « Rejeter tout » et confirmez que vous souhaitez enregistrer vos choix.`,
                            },
                            {
                                title: 'Strictement nécessaire',
                                description: 'Ces cookies sont indispensables au bon fonctionnement du site et ne peuvent pas être désactivés. Cela inclut le cc_cookie pour le gestionnaire de consentement aux cookies.',
    
                                //this field will generate a toggle linked to the 'necessary' category
                                linkedCategory: 'necessary',
                                cookieTable: {
                                    caption: 'Tableau des témoins de navigation',
                                    headers: {
                                        name: 'Cookie',
                                        domain: 'Domain',
                                        lifeSpan: 'Durée',
                                        desc: 'Description'
                                    },
                                    body: [
                                        {
                                            name: 'cc_cookie',
                                            domain: location.hostname,
                                            lifeSpan: "3 jours",
                                            desc: "Ce cookie permet de conserver votre choix aux termes des préférences d'utilisation des cookies.",
                                        }
                                    ]
                                }
                            },
                            {
                                title: 'Performances et analyses',
                                description: 'Ces cookies collectent des informations sur la manière dont vous utilisez notre site Web. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.',
                                linkedCategory: 'analytics',
                                cookieTable: {
                                    caption: 'Tableau des témoins de navigation',
                                    headers: {
                                        name: 'Témoins',
                                        domain: 'Domaine',
                                        lifeSpan: 'Durée',
                                        desc: 'Description'
                                    },
                                    body: [
                                        {
                                            name: '_ga',
                                            domain: location.hostname,
                                            lifeSpan: "90 jours",
                                            desc: 'Témoin de navigation en lien avec Google Analytics',
                                        },
                                        {
                                            name: '_gid',
                                            domain: location.hostname,
                                            lifeSpan: "90 jours",
                                            desc: 'Témoin de navigation en lien avec Google Analytics',
                                        }
                                    ]
                                }
                            },
                            {
                                title: 'Ciblage et publicité',
                                description: "Ces cookies sont utilisés pour rendre les messages publicitaires plus pertinents pour vous et vos intérêts. L'objectif est d'afficher des publicités pertinentes et attrayantes pour l'utilisateur individuel et donc plus précieuses pour les éditeurs et les annonceurs tiers.",
                                linkedCategory: 'ads',
                                cookieTable: {
                                    caption: 'Tableau des témoins de navigation',
                                    headers: {
                                        name: 'Témoins',
                                        domain: 'Domaine',
                                        lifeSpan: 'Durée',
                                        desc: 'Description'
                                    },
                                    body: [
                                        {
                                            name: '_gcl_au',
                                            domain: location.hostname,
                                            lifeSpan: "90 jours",
                                            desc: "Il s'agit d'un cookie requis pour la gestion d'une campagne Google adds pour promouvoir l'Auberge sur Mer",
                                        }
                                    ]
                                }
                            },
                            {
                                title: 'Vous avez des questions?',
                                description: 'Pour toute question relative à la politique de confidentialité et à vos choix et préférences en matière de témoins de navigations, veuillez <a href="#nous-joindre">nous contacter</a>'
                            }
                        ]
                    }
                }
            }
        }
      });
    }
      

    
  }, [langcode]);

  return null;
};

export default CookieConsent;